<template>
  <div>

    <app-step step="3" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-box-heart"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Confirmar orden</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">

      <div class="row text-muted pt-2 h6 px-3">
        <div class="col-sm-6 d-none d-md-block">Producto</div>
        <div class="col-sm-2 d-none d-md-block text-right">Cantidad</div>
        <div class="col-sm-2 d-none d-md-block text-right">Subtotal</div>
        <div class="col-sm-2 d-none d-md-block text-right">Total</div>
      </div>

      <div v-for="(item, key) in order.list" :key="key"
        class="row text-muted my-2 px-3 pb-2">
        <div class="col-sm-6">({{ item.model }}) {{ item.description }}</div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Cantidad: </span>
            <span class="col-md-12 col-3">{{ item.quantity }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Subtotal: </span>
            <span class="col-md-12 col-3">$ {{ item.subtotal }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Total: </span>
            <span class="col-sm-12 col-3">$ {{ item.total }}</span>
          </div>
        </div>
      </div>

      <hr class="mb-0">

      <div class="row text-muted">
        <div class="col-6 col-sm-6 pt-2"></div>
        <div class="col-3 col-sm-4 pt-2 text-right border border-right-0 border-bottom-0 border-top-0"><strong class="text-primary">Subtotal</strong></div>
        <div class="col-3 col-sm-2 pt-2 text-right border border-left-0 border-bottom-0 border-top-0">$ {{ subtotal }}</div>
      </div>

      <div class="row text-muted pt-2">
        <div class="col-1 col-sm-6"></div>
        <div class="col-8 col-sm-4 text-right border-left"><strong class="text-primary">{{ shipping.method }}</strong></div>
        <div class="col-3 col-sm-2 text-right border-right">$ {{ shipping.total }}</div>
      </div>

      <div class="row text-muted pt-2">
        <div class="col-6 col-sm-6 pb-2"></div>
        <div class="col-3 col-sm-4 pb-2 text-right border border-right-0 border-top-0"><strong class="text-primary">{{ payment.method }} - Total</strong></div>
        <div class="col-3 col-sm-2 pb-2 text-right border border-left-0 border-top-0"><strong>$ {{ total }}</strong></div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-4 ml-auto px-0">
          <b-button v-on:click="add_order()" variant="primary" block>Siguiente</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Order',
  data() {
    return {
      xwt: '',
      customer: '',
      order: '',
      order_id: '',
      subtotal: '',
      shipping: '',
      payment: '',
      total: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    async add_order() {
      
      var headers = { xwt: this.xwt }

      var data = {
        order: {
          customer_id: this.customer.customer_id,
          customer_group_id: this.customer.customer_group_id,
          firstname: this.customer.firstname,
          lastname: this.customer.lastname,
          email: this.customer.email,
          telephone: this.customer.telephone,

          payment_firstname: this.shipping.data.firstname,
          payment_lastname: this.shipping.data.lastname,
          payment_company: this.shipping.data.firstname,
          payment_address_1: this.shipping.data.address,
          payment_city: this.shipping.data.city,
          payment_postcode: this.shipping.data.postcode,
          payment_country: this.shipping.data.country,
          payment_country_id: this.shipping.data.country_id,
          payment_zone: this.shipping.data.zone,
          payment_zone_id: this.shipping.data.zone_id,
          payment_method: this.payment.method,
          payment_code: this.payment.code,

          shipping_firstname: this.shipping.data.firstname,
          shipping_lastname: this.shipping.data.lastname,
          shipping_company: this.shipping.data.firstname,
          shipping_address_1: this.shipping.data.address,
          shipping_city: this.shipping.data.city,
          shipping_postcode: this.shipping.data.postcode,
          shipping_country: this.shipping.data.country,
          shipping_country_id: this.shipping.data.country_id,
          shipping_zone: this.shipping.data.zone,
          shipping_zone_id: this.shipping.data.zone_id,
          shipping_method: this.shipping.method,
          shipping_code: this.shipping.code,

          comment: this.shipping.data.comment,
          total: this.total,
          order_status_id: '1',
          ip: this.customer.ip
        },
        order_product: this.order.list,
        order_total: {
          subtotal: this.subtotal,
          shipping: this.shipping.method,
          value: this.shipping.total,
          total: this.total
        }
      }

      axios.post(settings.api().endpoint + settings.api().path + '/add_order', data, { headers }).then( (r) => {
        this.order_id = r.data.data

        this.$store.commit('set_order_final', data)
        this.$cookies.remove("store_order")
        this.$router.push('/resume')
      })

    }
  },
  created() {
    this.xwt = this.$store.getters.get_token
    this.order = this.$store.getters.get_order
    this.customer = this.$store.getters.get_customer
    this.shipping = this.$store.getters.get_shipping
    this.payment = this.$store.getters.get_payment

    this.subtotal = this.order.data.total
    this.total = this.subtotal + this.shipping.total
  },
}
</script>